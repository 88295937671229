.App {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.top-banners {
  margin-inline: 80px;
  gap: 36px;
  display: flex;
  margin-top: 108px;
}

.left-top-banner {
  width: 770px;
  height: 589px;
  background-color: #94CC68;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    position: absolute;
    z-index: 1;
  }

  h1 {
    font-size: 85px;
    margin-bottom: 0px;
    margin-top: 220px;
    z-index: 2;

  }
  h2 {
    font-size: 30px;
    margin-top: 0px;
    margin-right: 80px;
    text-align: right;
    font-weight: 800;
    z-index: 2;
  }

  h3 {
    font-size: 15px;
    text-align: right;
    margin-right: 50px;
    margin-bottom: 30px;
  }
}

.right-top-banner {
  width: 475px;
  height: 589px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  gap: 140px;
  h2 {
    color:white;
    font-size: 38px;
    margin-top: 250px;
  }
}

.titles-top-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-inline: 87px;
}

.top-title {
  background-color: #94CC68;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
  }
  
}

.grid-part {
  width: 741px;
  height: 733px;
  display: flex;
  flex-wrap: wrap;
  gap:28px;
  margin-top: 104px;

  img {
    position: absolute;
    left:56%;
    bottom: -33%;
    /* bottom:30%; */
  }

  .empty-square {
    width: 223px;
    height: 231px;
    display: flex;
    flex-direction: column;
    background-color: #F3F3F3;

    h1 {
      font-size: 104px;
      margin-bottom: 0px;
      margin-top: 0px;
      text-align: left;
      margin-inline: 14px;
    }

    h2 {
      font-size: 22px;
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    h3 {
      font-size: 14px;
      text-align: left;
      margin-inline: 14px;
      margin-top: 0px;
    }
  }

  .square {
    width: 223px;
    height: 231px;
    display: flex;
    flex-direction: column;
    background-color: #F3F3F3;

    h1 {
      font-size: 104px;
      margin-bottom: 0px;
      margin-top: 0px;
      text-align: left;
      margin-inline: 14px;
    }

    h2 {
      font-size: 22px;
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    h3 {
      font-size: 14px;
      text-align: left;
      margin-inline: 14px;
      margin-top: 0px;
    }
  }

}

.central-banner {
  background-color: #F3F3F3;
  width: 1060px;
  height: 303px;
  margin-top: 104px;
  display: flex;

  .text-banner {
    width: 40%;
    margin-left: 64px;
  }

  img {
    object-fit: contain;
    position: absolute;
    height: 303px;
    z-index: 0;
  }

  h2 {
    z-index: 1;
    text-align: left;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 400;
  }
  h1 {
    z-index: 1;
    text-align: left;
    font-size: 60px;
    font-weight: 800;
    margin-bottom: 0px;
  }
}


.central-table {
  margin-top: 42px;
  width: 926px;
  background-color: #000000;
  color: white;
  display: flex;
  text-align: left;
  height: 274px;

  h3 {
    font-size: 18px;
    margin-top: 30px;
    font-weight: 400;
  }
}

.timetable {
  margin-top: 104px;
  width: 100%;
  height: 622px;
  display: flex;
  gap:50px;
  background-size: cover;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 60px;
    font-weight: 800;
    text-align: left;
    margin-left: 160px;
  }

  img {
    position: absolute;
  }
  .line {
    display: flex;
    align-items: center;
    gap:32px;

    h3 {
      text-align: left;
    }

    .time {
      min-width: 110px;
      height: 51px;
      background-color: #000;
      color:white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      

      h3 {
        margin-top: 0px;
        margin-bottom: 0px;
        
      }
    }
  }
}

.partners {
  margin-top: 104px;
  display: flex;
  gap: 200px;
  margin-inline: 137px;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: left;
    font-size: 60px;
    margin-bottom: 0px;
  }

  h2 {
    text-align: left;
    font-size: 20px;
  }
  h3 {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    margin-top: 50px;
  }

  spam {
    background-color: #94CC68;
    padding-left: 8px;
  }


  .prizes {
    display: flex;
    gap: 8px;
    align-items: end;
    

    h1 {
      margin-bottom: 0px;
      transform: translateY(30px);
      font-size: 128px;
      text-align: center;
    }

    .blackbox {
      color: white;
      background-color: #000;
      max-width: 177px;
      display: flex;
      align-items:end;
      justify-content: center;

      h3 {
        text-align: center;
        width: 80%;
      }
    }

  }
}

.sponsors {
  margin-inline: 235px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1 {
    text-align: left;
    font-size: 60px;
  }

  .cards {
    display: flex;
    gap:20px;

    .card {
      height: 177px;
      width: 290px;
      display: flex;
      align-items: center;

      h2 {
        margin-left: 32%;
      }
    }

  }

  .topics {
    display: grid;
    margin-left: 40px;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    grid-auto-flow: dense;
    gap: 20px;

    .topic-pont {
        display: flex;
        gap: 28px;
        h3 {
          text-align: left;
          font-weight: 400;
        }
    }
  }
}

.green-block {
  background-color: #94CC68;
  width: 100%;
  min-height: 416px;
  color:#000;
  margin-top: 104px;
  text-align: left;

  h1 {
    font-size: 60px;
  }
}
