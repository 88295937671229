.header-mobile {
  width: 100vw;
  /* min-height: 764px; */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  background-repeat: no-repeat;

  .rosmol {
    color: white;
    font-weight: 600;
    font-size: 10px;
    margin-top: 180px;
    text-align: center;
  }

  .titles-top-banner {
    margin: 0px;
    width: 100%;
    justify-content: center;
  }

  h1 {
    text-align: center;
    color: white;
    font-size: 60px;
    margin-bottom: 10px;
    font-weight: 900;
    margin-top: 90px;
    line-height: 60px;
  }

  h2 {
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 900;
    margin-top: 60px;
    line-height: 20px;
  }

  h3 {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 900;
    width: 80%;
    line-height: 20px;
    /* line-height: 60px; */
    margin-top: 0px;
  }

  p {
    font-weight: 900;
  }

}

.mobile-grid {

  .squares {
    display: grid;
    grid-template-columns: 145px 145px;
    justify-content: center;
    gap: 14px;
    .square {
      width: 145px;
      height: 177px;
      background-color: #F3F3F3;

      h1 {
        font-size: 60px;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-inline: 8px;
      }
      h3 {
        margin-inline: 8px;
        font-size: 10px;
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
  }
  .grid-header {
    display: flex;
    justify-content: center;
    /* gap: 20px; */
    margin-top: 40px;
    align-items: flex-start;
    padding-inline: 40px;

    
    h2 {
      color: #94CC68;
      width: 50%;
      font-weight: 900;
      font-size: 30px;
      /* text-align: right; */
      margin-top: 0px;
    }
    h3 {
      width: 50%;
      font-size: 12px;
    }
  }
}

.mobile-banner {
  background-color: #F3F3F3;
  min-height: 154px;
  margin-top: 40px;
  display: flex;
  /* overflow: hidden; */

  .text-banner {
    width: 40%;
    margin-left: 40px;
    h1 {
      font-size: 22px;
      font-weight: 800;
    }
    h2 {
      font-size: 10px;
    }
  }

  img {
    object-fit: contain;
    position: absolute;
    /* left: 0; */
    /* width: 390px; */
    height: 154px;
  }
}

.black-banner {
  margin-top: 20px;
  background-color: black;
  color: white;
  margin-inline: 40px;
  display: flex;
  h3 {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.timetable-mobile {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  background-color: #94CC68;
  padding-bottom: 20px;
  h1 {
    font-size: 22px;
    font-weight: 900;
    margin-left: 40px;
    margin-top: 40px;
    width: 50%;
  }
  .line {
    display: flex;
    gap: 8px;
    align-items: center;
    h3 {
      font-size: 10px;
    }

    .time {
      background-color: black;
      color: white;
      min-width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        font-size: 11px;
        line-height: 11px;
      }
    }
  }
}

.partners-mobile {
  margin-top: 40px;
  padding-left: 40px;
  h1 {
    font-size: 22px;
    font-weight: 900;
  }
  h2 {
    font-size: 10px;
  }
  h3 {
    font-size: 10px;
  }

  .prizes {
    display: flex;
    justify-content: flex-start;
    gap:8px;
    align-items: flex-end;
    h1 {
      font-size: 60px;
      margin-bottom: 0px;
      margin-top: 0px;
      text-align: center;
      transform: translateY(14px);
    }
    .blackbox {
      width: 96px;
      text-align: center;
      background-color: black;
      color: white;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      h3 {
        width: 80%;
      }
    }
  }
}

.label-mobile {
  margin-top: 40px;
  margin-left: 40px;
  h1 {
      font-size: 22px;
      font-weight: 900;
      margin-bottom: 10px;
  }
  h2 {
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.grass-banner {
  background-size: contain;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.sponsors-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;

  .card {
    height: 131px;
    background-size: contain;
    width: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 14px;
      width: 40%;
      margin-left: 16px;
      font-weight: 900;
    }
  }
}

.topics-mobile {
  margin-left: 40px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 160px 160px;
  gap: 8px;

  .topic-pont {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 20px;
    }
    h3 {
      font-size: 9px;
    }
  }
}

.smi {
  background-color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  h1 {
    color: white;
    font-size: 22px;
    margin-left: 20px;
  }

}

.green-footer {
  background-color: #94CC68;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
  h1 {
    font-size: 22px;
  }

  h3 {
    font-size: 10px;
  }
}